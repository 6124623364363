.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid grey;
  border-radius: 10px;
  background: rgba(128, 128, 128, 0.171);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: rgb(2, 177, 207);
  border-color: #00004d;
}

.button--inverse {
  background: rgb(2, 177, 207);
  color: #f2f2fa;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: rgb(0, 207, 0);
}

.button--danger {
  background: rgba(128, 128, 128, 0.171);
  border-color: rgb(226, 244, 247);
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
