.truck-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 50rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}
