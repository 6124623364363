@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: rgb(226, 244, 247);
  color: grey;
  background-image: url(/static/media/highway.66f5cacf.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}
.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  position: relative;
  overflow: hidden;
  background-color: #00001a;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  opacity: 0.7;
}
/*background-color: #00001a; */

.users-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid grey;
  border-radius: 10px;
  background: rgba(128, 128, 128, 0.171);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: rgb(2, 177, 207);
  border-color: #00004d;
}

.button--inverse {
  background: rgb(2, 177, 207);
  color: #f2f2fa;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: rgb(0, 207, 0);
}

.button--danger {
  background: rgba(128, 128, 128, 0.171);
  border-color: rgb(226, 244, 247);
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}

.truck-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.truck-item {
  margin: 0.8rem 0;
  color: white;
}

.truck-item__content {
  padding: 0;
}

.truck-item__info {
  padding: 1rem;
}

.truck-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.truck-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.truck-item__info h2,
.truck-item__info h3,
.truck-item__info p {
  margin: 0 0 0.4rem 0;
}

.truck-item__actions {
  border-top: 1px solid #ccc;
  text-align: center;
  padding: 1rem;
}

.truck-item__modal-content {
  padding: 15px;
}

.truck-item__modal-actions {
  text-align: right;
}

.truck-item__actions button,
.truck-item__actions a {
  margin: 0.5rem;
}
.map-container {
  height: 32rem;
  width: 100%;
}

.offDutyTeam {
  background-color: grey;
  border-radius: 8px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 20px;
}
.container {
  display: grid;
  grid-template-columns: auto auto auto;
  flex-wrap: nowrap;
  text-align: center;
  grid-auto-rows: 1fr;
}

.container > div {
  margin: 10px;
}

.offDuty {
  background-color: #4d001e;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  min-width: 100%;
  color: white;
}

.onDuty {
  color: white;
  background-color: green;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  min-width: 100%;
  align-self: right;
}

.offDuty2 {
  font-size: 0.85em;
  padding-right: 10px;
  padding-left: 10px;
}
.numberPlaceholder {
  visibility: hidden;
}
.offDuty3 {
  font-size: 0.85em;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  color: grey;
}
.truck-item__actions {
  grid-auto-rows: 1fr;
}
.locdate {
  font-size: 0.7em;
}
.icon70 {
  size: 30px;
}

.true {
  background-color: red;
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
}
.reset10hr {
  margin-top: 12px;
  font-size: 0.9em;
  text-align: right;
  color: rgb(0, 255, 128);
}
.secondDriver {
  font-size: 0.8em;
}

.mobileOptCoDriver {
  display: none;
}

.placeholder {
  visibility: hidden;
}
@media (min-width: 768px) {
  .truck-item__image {
    height: 20rem;
  }
  .mobileOptCoDriver {
    display: inline-block;
  }
}

@media (max-width: 650px) {
  .mobileOpt2 {
    display: none;
  }
}

@media (max-width: 520px) {
  .mobileOpt {
    display: none;
  }
}

.truck-list {
  list-style: none;
  margin: 0.6rem auto;
  padding: 0;
  width: 97%;
  max-width: 97rem;
}

.cardcontainer {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  grid-auto-rows: 1fr;
}
.carddiv {
  padding: 5px;
  margin: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.row2 {
  margin: 10px;
}
@media (max-width: 2000px) {
  .cardcontainer {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 1050px) {
  .cardcontainer {
    display: grid;
    grid-template-columns: auto;
  }
}

.truck-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 50rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
  border-radius: 14px;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

.logo {
  text-align: center;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #00001a;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}
/*  background: #00001a; */
main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links li {
    margin: 1rem;
  }
  
  .nav-links a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  .nav-links a:hover,
  .nav-links a:active,
  .nav-links a.active {
    background: #f8df00;
    border-color: #292929;
    color: #292929;
  }
  
  .nav-links button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links button:focus {
    outline: none;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #292929;
    color: white;
  }
  
  @media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
    }
  
    .nav-links li {
      margin: 0 0.5rem;
    }
  
    .nav-links a {
      color: white;
      text-decoration: none;
    }
  
    .nav-links button {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
    
    .nav-links button:hover,
    .nav-links button:active {
      background: #f8df00;
      color: #292929;
    }
  }
  
.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}

.goodScore {
  color: teal;
}
.mediumScore {
  color: yellow;
}
.circleScore {
  border-radius: 12px;
  padding: 3px;
  margin-top: 10 px;
}
.circleScore2 {
  border-radius: 12px;
  padding: 3px;
  margin-top: 10 px;
}

.unsafeScore {
  color: red;
}

.badScore {
  color: orange;
}
.container_scorecard {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.gridItem_scorecard {
  padding: 10px;
  border: 1px solid black;
  text-align: center;
}

.driverName {
  text-align: center;
  background-color: black;
  color: aliceblue;
}
.Buttons {
  display: flex;

  justify-content: right;
}

.driversDiv {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  row-gap: 5px;
}

