.truck-item {
  margin: 0.8rem 0;
  color: white;
}

.truck-item__content {
  padding: 0;
}

.truck-item__info {
  padding: 1rem;
}

.truck-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.truck-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.truck-item__info h2,
.truck-item__info h3,
.truck-item__info p {
  margin: 0 0 0.4rem 0;
}

.truck-item__actions {
  border-top: 1px solid #ccc;
  text-align: center;
  padding: 1rem;
}

.truck-item__modal-content {
  padding: 15px;
}

.truck-item__modal-actions {
  text-align: right;
}

.truck-item__actions button,
.truck-item__actions a {
  margin: 0.5rem;
}
.map-container {
  height: 32rem;
  width: 100%;
}

.offDutyTeam {
  background-color: grey;
  border-radius: 8px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 20px;
}
.container {
  display: grid;
  grid-template-columns: auto auto auto;
  flex-wrap: nowrap;
  text-align: center;
  grid-auto-rows: 1fr;
}

.container > div {
  margin: 10px;
}

.offDuty {
  background-color: #4d001e;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  min-width: 100%;
  color: white;
}

.onDuty {
  color: white;
  background-color: green;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  min-width: 100%;
  align-self: right;
}

.offDuty2 {
  font-size: 0.85em;
  padding-right: 10px;
  padding-left: 10px;
}
.numberPlaceholder {
  visibility: hidden;
}
.offDuty3 {
  font-size: 0.85em;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  color: grey;
}
.truck-item__actions {
  grid-auto-rows: 1fr;
}
.locdate {
  font-size: 0.7em;
}
.icon70 {
  size: 30px;
}

.true {
  background-color: red;
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
}
.reset10hr {
  margin-top: 12px;
  font-size: 0.9em;
  text-align: right;
  color: rgb(0, 255, 128);
}
.secondDriver {
  font-size: 0.8em;
}

.mobileOptCoDriver {
  display: none;
}

.placeholder {
  visibility: hidden;
}
@media (min-width: 768px) {
  .truck-item__image {
    height: 20rem;
  }
  .mobileOptCoDriver {
    display: inline-block;
  }
}

@media (max-width: 650px) {
  .mobileOpt2 {
    display: none;
  }
}

@media (max-width: 520px) {
  .mobileOpt {
    display: none;
  }
}
