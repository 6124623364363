.truck-list {
  list-style: none;
  margin: 0.6rem auto;
  padding: 0;
  width: 97%;
  max-width: 97rem;
}

.cardcontainer {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  grid-auto-rows: 1fr;
}
.carddiv {
  padding: 5px;
  margin: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.row2 {
  margin: 10px;
}
@media (max-width: 2000px) {
  .cardcontainer {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 1050px) {
  .cardcontainer {
    display: grid;
    grid-template-columns: auto;
  }
}
