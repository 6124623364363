.goodScore {
  color: teal;
}
.mediumScore {
  color: yellow;
}
.circleScore {
  border-radius: 12px;
  padding: 3px;
  margin-top: 10 px;
}
.circleScore2 {
  border-radius: 12px;
  padding: 3px;
  margin-top: 10 px;
}

.unsafeScore {
  color: red;
}

.badScore {
  color: orange;
}
.container_scorecard {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.gridItem_scorecard {
  padding: 10px;
  border: 1px solid black;
  text-align: center;
}

.driverName {
  text-align: center;
  background-color: black;
  color: aliceblue;
}
.Buttons {
  display: flex;

  justify-content: right;
}
