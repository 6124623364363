.card {
  position: relative;
  overflow: hidden;
  background-color: #00001a;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  opacity: 0.7;
}
/*background-color: #00001a; */
